// 酒店详情 http://192.168.0.19:8765/doc.html#/haolv-consumer/hotel-search-controller/getRoomPackageInfoUsingPOST

const __request = require(`./__request/__request_contentType_json`);
const consumer_hotel_search_getRoomPackageInfo = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/hotel/search/getRoomPackageInfo',
        data: {params: pParameter },
        enable_error_alert: false // 临时隐藏
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_hotel_search_getRoomPackageInfo;