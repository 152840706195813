// /consumer/hotel/search/getHotelImageV2 酒店相册 https://api-alpha.wholeok.com//doc.html#/yinzhilv-tmc/hotel-search-controller/getHotelImageV2UsingPOST
const __request = require(`./__request/__request_contentType_json`);
const consumer_hotel_search_getHotelImageV2 = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/hotel/search/getHotelImageV2',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_hotel_search_getHotelImageV2;
