// 酒店创建预订单 https://api-beta.wholeok.com/doc.html#/yinzhilv-tmc/%E9%85%92%E5%BA%97%E8%AE%A2%E5%8D%95/addOrUpdByOnBusinessUsingPOST

const __request = require(`./__request/__request_contentType_json`);
const consumer_t_od_hotel_order_interim_addOrUpdByOnBusiness = (pParams) => {

    const p = {
        method: 'post',
        urlSuffix: '/consumer/t-od-hotel-order-interim/addOrUpdByOnBusiness',
        data: pParams,
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_t_od_hotel_order_interim_addOrUpdByOnBusiness;
