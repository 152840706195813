//高德地图操作手册 https://lbs.amap.com/api/javascript-api/guide/abc/quickstart

import Q from 'q';
import _ from 'underscore';
import extend from "extend";
import get_uuid from "nllib/src/common/get_uuid";

export default {
    data() {
        return {
            uid: '',
        }
    },
    methods: {
        //初始化
        init(pParameter) {
            const defaultParameter = {
                center: [116.3972, 39.9096],
                zoom: 16
            };
            const parameter = extend(true, {}, defaultParameter, pParameter);
            if (!_.isArray(parameter.center) || parameter.center.length !== 2) {
                const msg = `init map pParameter error，code=001`;
                console.error(msg);
                console.trace();
                return Q.reject({msg: msg});
            }
            this.uid = get_uuid();
            this.$nextTick(function () {
                const container = this.$refs['amap-' + this.uid];
                const map = new AMap.Map(container, parameter);

                //创建点标记
                const marker = new AMap.Marker({
                    // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
                    position: new AMap.LngLat(parameter.center[0], parameter.center[1]),
                });
                //将创建的点标记添加到已有的地图实例：
                map.add(marker);
            })
        }
    }
}
